<template>
  <div class="row justify-content-center align-items-md-center">
    <div class="col-md-11">
      <div class="card full-height bg-ligth">
        <div class="card-header d-flex justify-content-between">
  <h3 class="mb-0">Caixa</h3>
  <v-btn @click="gerarEtiquetas" color="warning">Gerar Etiquetas</v-btn>
</div>
        <div class="card-body">
          <b-table
              :fields="['selected','numeracao','status','descricao','created_at', 'acoes']"
              :items="lista_caixa"
              :per-page="perPage"
              :current-page="currentPage"
              id="grupo-table"
              class="table table-vertical-center table-head-bg table-borderless"
              show-empty
              empty-text="Nenhum registro encontrado!"
              :select-mode="selectMode"
      responsive="sm"
      ref="selectableTable"
      selectable
      @row-selected="onRowSelected"
          >
          <template #cell(selected)="{ rowSelected }">
        <template v-if="rowSelected">
          <span aria-hidden="true">&check;</span>
          <span class="sr-only">Selected</span>
        </template>
        <template v-else>
          <span aria-hidden="true">&nbsp;</span>
          <span class="sr-only">Not selected</span>
        </template>
      </template>
                 <template #head(acoes)><span></span></template>
                 <template #head(created_at)>criação</template>


                 <template #cell(created_at)="{item}">
                <span >
                  {{ item.created_at | formatEnToBr}}
                </span>
              </template>
              <template #cell(status)="{item}">
                <span  class="label label-lg label-inline px-2 pb-2 pt-3"
            :class="{
              'label-light-success': item.status == 1,
              'label-light-warning': item.status === null,
            }">{{ item.status == 1 ? 'Emitida'  : 'Criada' }}</span>
                </template>
                    <template #cell(acoes)="{item}">
              <div class="text-right w-100"> 
          
                <button
                    v-show="lista_permissoes_filial.u_Caixa"
                    @click="atualizar(item)"
                          class="btn btn-icon btn-light btn-sm mx-1"
                    v-b-tooltip.hover title="Editar registro"
                >
                  <i class="far fa-edit text-primary"></i>
                </button>

                <button
                    v-show="lista_permissoes_filial.lock_Caixa"
                    @click="confirm(item)"
                          class="btn btn-icon btn-light btn-sm mx-1"
                    v-b-tooltip.hover title="Bloquear/Desbloquear registro"
                >
                  <i class="fas fa-unlock-alt text-danger"></i>
                </button>
                           </div>
            </template>
          </b-table>
          <b-pagination
              v-model="currentPage"
              :total-rows="lista_caixa.length"
              :per-page="perPage"
              aria-controls="grupo-table"
          >
          </b-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {SET_BREADCRUMB} from "@/core/store/breadcrumbs.module";
import { fireAlert } from "@/core/mixins/alertMessageMixin";
  import dateFormat from "@/core/mixins/dateFormat"
  export default {
    mixins: [fireAlert,dateFormat],
  data() {
    return {
  
         currentPage: 1,
      perPage: 10,
      selectMode: 'multi',
        selected: [],
        modes: ['multi', 'single', 'range'],
    };
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{title: "Caixa"}]);
  },
  created() {
    this.listar_caixas();
  },
  computed: {
    lista_caixa() {
      return this.$store.state.caixa.lista_caixa;
    },
    lista_permissoes_filial() {
      return this.$store.state.sistema.lista_permissoes_filial;
    },
    mensagem_alert() {
      return this.$store.state.configEmpresa.mensagem_alert;
    },
  },
  methods: {
    async listar_caixas() {
     
      await this.$store.dispatch("caixa/listar_caixas").finally(() => {
       
        this.$store.dispatch("configEmpresa/atualizar", "");
      });
    },
    atualizar(value) {
      this.$router.push({name: "createCaixa"});
      this.$store.dispatch("configEmpresa/atualizar", value);

    },
    async confirm(value) {
      await this.$confirm({
        title: "Estas seguro?",
        message: `Esta ação bloqueara este Grupo no sistema?`,
        button: {
          no: "No",
          yes: "Si",
        },
        callback: (confirm) => {
          if (confirm) {
            this.bloquear(value);
          }
        },
      });
    },
    async bloquear(value) {
      await this.$store.dispatch("configEmpresa/delete_filial", value);
      this.fireAlert({
          ...this.mensagem_alert
        });
    },
    onRowSelected(items) {
        this.selected = items
      },
      selectAllRows() {
        this.$refs.selectableTable.selectAllRows()
      },
      clearSelected() {
        this.$refs.selectableTable.clearSelected()
      },
      selectThirdRow() {
        // Rows are indexed from 0, so the third row is index 2
        this.$refs.selectableTable.selectRow(2)
      },
      unselectThirdRow() {
        // Rows are indexed from 0, so the third row is index 2
        this.$refs.selectableTable.unselectRow(2)
      },

      gerarEtiquetas(){
        
        let a = []
        this.selected.forEach(element => {
          a.push(element.id)
        });

       this.$store.dispatch('documentos/gerar_etiquetas',{ids:a})

      }
 
  },
};
</script>

<style scoped>
.lista:hover {
  background-color: rgba(167, 183, 255, 0.705);
}
</style>